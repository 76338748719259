import { render, staticRenderFns } from "./LangsJson.vue?vue&type=template&id=62c8f9b0&scoped=true&"
import script from "./LangsJson.vue?vue&type=script&lang=js&"
export * from "./LangsJson.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c8f9b0",
  null
  
)

export default component.exports