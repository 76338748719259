import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0 15px"}},[_c('div',{staticStyle:{"padding":"15px 0"}},[_vm._v(" You can download "),(_vm.landing.advanced.selected_langs.length>1)?_c('span',[_vm._v("and upload")]):_vm._e(),_vm._v(" the file with translations. "),_c('br'),(_vm.landing.advanced.selected_langs.length>1)?_c('span',[_vm._v("Translations will be added to all sections of your application.")]):_c('span',[_vm._v("If you want to add a translation, then activate the desired languages first")])]),_c(VBtn,{staticStyle:{"margin-right":"15px"},attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.downloadLangs}},[_c(VIcon,[_vm._v("mdi-download")]),_vm._v("Download language file")],1),(_vm.landing.advanced.selected_langs.length > 1)?_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.changeShowUpload}},[_c(VIcon,[_vm._v("mdi-upload")]),_vm._v("Upload")],1):_vm._e(),_c(VDialog,{attrs:{"fullscreen":_vm.$store.state.isMobile,"max-width":"400px"},on:{"click:outside":_vm.closeUpload},model:{value:(_vm.showUpload),callback:function ($$v) {_vm.showUpload=$$v},expression:"showUpload"}},[_c(VCard,[_c(VIcon,{staticStyle:{"position":"absolute","top":"5px","right":"5px"},attrs:{"small":""},on:{"click":_vm.closeUpload}},[_vm._v("mdi-close")]),_c(VCardTitle,[_vm._v("Choose language")]),_c(VCardText,{staticStyle:{"padding-top":"15px"}},[_c(VSelect,{attrs:{"placeholder":"Choose lang for upload","items":_vm.langsForUpload,"error-messages":_vm.errorMessage,"error":_vm.error,"item-value":"value","item-text":"name","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"30px","height":"20px","margin-right":"10px"},attrs:{"src":item.flag,"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"30px","height":"20px","margin-right":"10px"},attrs:{"src":item.flag,"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}}),_c(VFileInput,{ref:"pdfUpload",attrs:{"accept":"text/csv","outlined":"","dense":"","placeholder":"Choose file","prepend-inner-icon":"mdi-file","prepend-icon":"","error":_vm.errorFile,"error-messages":_vm.errorFileMessage},on:{"change":_vm.onFileChange}})],1),_c(VCardActions,{staticStyle:{"display":"flex","justify-content":"flex-end","padding":"15px 20px"}},[_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.uploadBranding}},[_vm._v("Upload")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }